export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/contracts": [6],
		"/dataark": [7,[2]],
		"/edge": [~8,[3]],
		"/edge/ARmouryItem/[tokenId]": [10,[3]],
		"/edge/ARmoury": [~9,[3]],
		"/edge/connect": [11,[3]],
		"/edge/recon/0": [12,[3]],
		"/edge/recon/1": [13,[3]],
		"/edge/recon/2": [14,[3]],
		"/edge/recon/3": [15,[3]],
		"/edge/recruit": [16,[3]],
		"/edge/systemscheck": [17,[3]],
		"/embark": [~18],
		"/launch": [19],
		"/login": [20],
		"/logoutmobile": [22],
		"/logout": [~21],
		"/razor": [~23,[4]],
		"/razor/razorlogout": [24,[4]],
		"/test": [~25]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';